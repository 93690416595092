import React from 'react'
import TopicCard from './TopicCard'
import { Grid } from '@material-ui/core'

const TopicList = ({ topics, subject, answers }) => {

    return (
        <Grid container spacing={2}>
            {topics && topics.map((topic, index) => {
                return (
                    <Grid item xs={12} key={index}>
                        <TopicCard topic={topic} subject={subject} totalAnswers={answers && answers[topic.id] ? answers[topic.id].numberOfAnswers : 0} />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default TopicList