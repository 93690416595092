import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

// Replace this with your own config details
var config = {
    apiKey: "AIzaSyAQo1zNOjpDGqGVv_0-IuHq4TAQOr2k4_s",
    authDomain: "admissio-8d4a5.firebaseapp.com",
    databaseURL: "https://admissio-8d4a5.firebaseio.com",
    projectId: "admissio-8d4a5",
    storageBucket: "admissio-8d4a5.appspot.com",
    messagingSenderId: "420881335951",
    appId: "1:420881335951:web:37ab38106eb8db31d5d2fa",
    measurementId: "G-2HDB48ZZLV"
};
firebase.initializeApp(config);
firebase.firestore();
firebase.functions();

export default firebase 