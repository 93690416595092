import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import './App.css'
import theme from './theme'
import Navbar from './components/layout/Navbar'
import Functionbar from './components/layout/Functionbar'
import Bottombar from './components/layout/Bottombar'
import Dashboard from './components/dashboard/Dashboard'
import SignIn from './components/auth/SignIn'
import MainTest from './components/test/Test'
import Subject from './components/subject/Subject';
import Topic from './components/topic/Topic'
import Example from './components/example/Example';

class App extends Component {
  render() {
    return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <BrowserRouter>
        <div className="App">
          <Navbar/>
          <Functionbar/>
            <Switch >
            <Route exact path = '/' component = {Dashboard} />
            <Route exact path = '/iniciarsesion' component = {SignIn} />
            <Route exact path = '/examen' component = {MainTest} />
            <Route exact path = '/:subject' component = {Subject} />
            <Route exact path = '/:subject/:topic' component = {Topic} />
            <Route exact path = '/:subject/:topic/:example' component = {Example} />
            <Route path = "*">
              <Redirect to ='/'/>
            </Route>
          </Switch>
          <Bottombar/>
          
        </div>
      </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;