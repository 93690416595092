import React from 'react';
import {
    Fab,
    Card,
    CardContent,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Button,
    ButtonGroup,
    Box,
    Grid,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TextItem } from './text.item';
import { ImageItem } from './../general/image.item';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardStyle: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center'
    },
    media: {
        height: 'auto',
        maxHeight: 300,
        width: '100%',
        objectFit: 'contain',
    },
    formControl: {
        margin: theme.spacing(3),
    },

    buttonError: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        }
    },
    buttonSuccess: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        }
    },
    buttonWaiting: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    extendedIcon: {
        marginLeft: theme.spacing(1),
    },
    BlockMath: {
        fontSize: '1.5rem'
    }
}))



export const ExampleItem = ({
    example,
    subject,
    topic,
    text,
    answerValue,
    onSubmit,
    index,
    totalexamples,
    handleRadioChange,
    status,
    helperText
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={2} alignItems="stretch" justify="center">
                <Grid item xs={12}>
                    <Typography variant="overline" color="textSecondary" component="p">{"Ejercicio " + index + " de " + totalexamples}</Typography>
                </Grid>
                {
                    text && subject !== 'matematicas' && subject !== 'analitico' ?
                        <Grid item xs={8}>
                            <TextItem text={text} />
                        </Grid>
                        :
                        null
                }
                <Grid item xs height='100%'>
                    <Card className={classes.cardStyle} >
                        <CardContent >
                            <Box display='flex' flexDirection='column'>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <Typography variant="h6" component="p">
                                        {Array.isArray(example.question) ?
                                            example.question.map((value, index) => { return value.isFormula ? <InlineMath key={index}>{value.text}</InlineMath> : value.text }) : example.question}
                                    </Typography>
                                    {
                                        typeof example.img != 'undefined' && example.img ?
                                            Array.isArray(example.img) ?
                                                <Grid container justify="center">
                                                    {example.img.map((value, index) => { return <Grid key={index} item><ImageItem index={index} imgName={value} /></Grid> })}
                                                </Grid> :
                                                <ImageItem imgName={example.img} />
                                            :
                                            null
                                    }
                                    <RadioGroup aria-label="options" name="options" value={answerValue} onChange={(value) => handleRadioChange(value.target.value)}>
                                        <FormControlLabel value="A" control={<Radio />} 
                                                        label={example.ansFormula ? 
                                                            <BlockMath>{example.op_A}</BlockMath> : 
                                                            example.ansImage ?
                                                            <ImageItem imgName={example.op_A} /> :
                                                            <Typography variant="body2" align='left' gutterBottom={true}>{example.op_A}</Typography>
                                                            } />
                                        <FormControlLabel value="B" control={<Radio />} 
                                                        label={example.ansFormula ? 
                                                            <BlockMath>{example.op_B}</BlockMath> : 
                                                            example.ansImage ?
                                                            <ImageItem imgName={example.op_B} /> :
                                                            <Typography variant="body2" align='left' gutterBottom={true}>{example.op_B}</Typography>} />
                                        <FormControlLabel value="C" control={<Radio />} 
                                                        label={example.ansFormula ? 
                                                            <BlockMath>{example.op_C}</BlockMath> : 
                                                            example.ansImage ?
                                                            <ImageItem imgName={example.op_C} /> :
                                                            <Typography variant="body2" align='left' gutterBottom={true}>{example.op_C}</Typography>} />
                                        <FormControlLabel value="D" control={<Radio />} label={example.ansFormula ? 
                                                            <BlockMath>{example.op_D}</BlockMath> : 
                                                            example.ansImage ?
                                                            <ImageItem imgName={example.op_D} /> :
                                                            <Typography variant="body2" align='left' gutterBottom={true}>{example.op_D}</Typography>} />
                                    </RadioGroup>

                                </FormControl>
                                <Box>
                                    <Fab
                                        variant="extended"
                                        // color="secondary"
                                        className={status == null ? classes.buttonWaiting : status ? classes.buttonSuccess : classes.buttonError}
                                        onClick={() => onSubmit()}
                                        disabled={answerValue ? false : true}>
                                        {helperText}
                                        {status == null ?
                                            <AssignmentTurnedInIcon className={classes.extendedIcon} /> :
                                            status ? <CheckIcon className={classes.extendedIcon} /> : <ClearIcon className={classes.extendedIcon} />}
                                    </Fab>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup variant="contained" color="primary">
                        {index === 1 || index === "1" ?
                            null :
                            <Button
                                component={NavLink}
                                to={'/' + subject + '/' + topic + '/' + (parseInt(index) - 1).toString()}
                                startIcon={<ArrowBackIosIcon />}>
                                Anterior
                    </Button>
                        }
                        {index === totalexamples || index === totalexamples.toString() ?
                            null :
                            <Button
                                component={NavLink}
                                to={'/' + subject + '/' + topic + '/' + (parseInt(index) + 1).toString()}
                                endIcon={<ArrowForwardIosIcon />}>
                                Siguiente
                    </Button>
                        }
                    </ButtonGroup>
                </Grid>
            </Grid>
        </div >
    );
}