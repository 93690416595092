import React from 'react'
import { Grid, Paper, Typography, makeStyles, List, ListItem, ListItemAvatar, Button, ListItemText, Avatar, Divider, Box } from '@material-ui/core'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ComputerIcon from '@material-ui/icons/Computer';
import DescriptionIcon from '@material-ui/icons/Description';
import Filter5Icon from '@material-ui/icons/Filter5';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import WebIcon from '@material-ui/icons/Web';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import CategoryIcon from '@material-ui/icons/Category';
import ClassIcon from '@material-ui/icons/Class';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        fontWeight: 700,
        color: theme.palette.info.main,
        padding: theme.spacing(2, 2, 1, 5)
    },
    plan: {
        // width: '100%',
        // height: '100%',
        align: 'center'
    },
    header1: {
        fontWeight: 750,
        textTransform: 'uppercase',
        color: theme.palette.secondary.light,
    },
    header2: {
        fontWeight: 750,
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
    },
    header3: {
        fontWeight: 750,
        textTransform: 'uppercase',
        color: theme.palette.info.dark,
    },
    icons1: {
        color: '#fff',
        backgroundColor: theme.palette.secondary.light,
    },
    icons2: {
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,
    },
    icons3: {
        color: '#fff',
        backgroundColor: theme.palette.info.dark,
    },
    bullet1: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light,
    },
    bullet2: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    bullet3: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.info.dark,
    },
    buttonGamma: {
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
    },
    buttonBeta: {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
    },
    buttonAlpha: {
        color: theme.palette.info.dark,
        borderColor: theme.palette.info.dark,
    },
}));

export default function Plans() {
    const classes = useStyles();
    return (
        <div>
            <Box >
                <Typography variant="h4" className={classes.title}>¡Programas para ti!</Typography>
                <Typography variant="body1" gutterBottom >
                    Con base en nuestra experiencia, hemos desarrollado para ti las siguientes opciones para lograr tu admsión a la <strong>carrera de tus sueños</strong>
                </Typography>
                <Grid container spacing={2} >
                    <Grid item md>
                        <Box display='flex' justifyContent='center' p={1} m={1}>
                            <Avatar m={1} className={classes.bullet1}>
                                <img width='15px'
                                    alt="icono admissio"
                                    src={require('./../static/curso_admissio.svg')} />
                            </Avatar>
                            <Typography variant='h5' className={classes.header1} >Plan Gamma</Typography>
                        </Box>
                        <Paper className={classes.plan}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons1}>
                                            <ComputerIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Plataforma Admissio" secondary="Acceso a plataforma digital por 5 meses" />
                                </ListItem>
                                <ListItem >
                                    <List>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <LibraryAddCheckIcon color="secondary" />
                                            </ListItemAvatar>
                                            <ListItemText secondary="Explicación de los temas que incluye el examen con ejemplos resueltos." />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <TrendingUpIcon color="secondary" />
                                            </ListItemAvatar>
                                            <ListItemText secondary="Ejercicios de diferente grado de dificultad." />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <WebIcon color="secondary" />
                                            </ListItemAvatar>
                                            <ListItemText secondary="Exámenes simuladores con las mismas características que el de UABC." />
                                        </ListItem>
                                    </List>
                                </ListItem>
                            </List>
                            <Box display='flex' justifyContent='center' p={2} >
                                <Button
                                    className={classes.buttonGamma}
                                    variant='outlined'
                                    target="_blank"
                                    href="https://wa.me/5216641744903?text=¡Me%20interesa%20saber%20más%20de%20Admissio!%20Podrías%20darme%20más%20información%20del%20Plan%20Gamma">
                                    ¡Me interesa este plan!</Button>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md>
                        <Box display='flex' justifyContent='center' p={1} m={1}>
                            <Avatar m={1} className={classes.bullet2}>
                                <img width='15px'
                                    alt="icono admissio"
                                    src={require('./../static/curso_admissio.svg')} />
                            </Avatar>
                            <Typography variant='h5' className={classes.header2} >Plan Beta</Typography>
                        </Box>
                        <Paper className={classes.plan}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons2}>
                                            <AddIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Plataforma Admissio" secondary="Todos los contenidos del Plan Gamma" />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons2}>
                                            <DescriptionIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Manual" secondary="Manual con ejercicios de repaso extras (adicionales a los de la aplicación) por competencias." />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons2}>
                                            <Filter5Icon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Taller intensivo" secondary="Taller presencial intensivo impartido por Jenny Morales (12.5 horas) para revisión de dudas y análisis de casos complejos." />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons2}>
                                            <AvTimerIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Sesiones estrategicas" secondary="Sesiones de estrategias para mejorar los tiempos de respuesta del examen." />
                                </ListItem>
                            </List>
                            <Box display='flex' justifyContent='center' p={2} >
                                <Button
                                    className={classes.buttonBeta}
                                    variant='outlined'
                                    target="_blank"
                                    href="https://wa.me/5216641744903?text=¡Me%20interesa%20saber%20más%20de%20Admissio!%20Podrías%20darme%20más%20información%20del%20Plan%20Beta">
                                    ¡Me interesa este plan!</Button>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md>
                        <Box display='flex' justifyContent='center' p={1} m={1}>
                            <Avatar m={1} className={classes.bullet3}>
                                <img width='15px'
                                    alt="icono admissio"
                                    src={require('./../static/curso_admissio.svg')} />
                            </Avatar>
                            <Typography variant='h5' className={classes.header3} >Plan Alpha</Typography>
                        </Box>
                        <Paper className={classes.plan}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons3}>
                                            <AddIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Plataforma Admissio" secondary="Todos los contenidos del Plan Gamma" />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons3}>
                                            <DescriptionIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Manual" secondary="Manual con ejercicios de repaso extras (no incluídos en la aplicación) por competencias." />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons3}>
                                            <HowToRegIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Curso extendido" secondary="Curso completo presencial impartido por Jenny Morales (42 horas)." />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons3}>
                                            <ClassIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Exámenes simuladores" secondary="Examenes simuladores presenciales con revisión de estrategias para mejorar tiempos de respuesta." />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons3}>
                                            <FavoriteIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Acompañamiento psicológico" secondary="Atención psicológica para manejo de ansiedad, técnicas de relajación y concentración, control de emociones." />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.icons3}>
                                            <CategoryIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Taller de estudio" secondary="Taller de técnicas de estudio y preparación." />
                                </ListItem>
                            </List>
                            <Box display='flex' justifyContent='center' p={2} >
                                <Button
                                    className={classes.buttonAlpha}
                                    variant='outlined'
                                    target="_blank"
                                    href="https://wa.me/5216641744903?text=¡Me%20interesa%20saber%20más%20de%20Admissio!%20Podrías%20darme%20más%20información%20del%20Plan%20Alpha">
                                    ¡Me interesa este plan!</Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Box m={1} p={1}>
                    <Typography variant='h6' >
                        Todos nuestros programas tienen <strong>cupo limitado</strong> para garantizar una <strong>atención personalizada.</strong>
                    </Typography>
                    <Typography variant='subtitle1'>
                        Para más detalles de cualquiera de nuestras opciones, por favor contáctanos
            </Typography>
                </Box>

            </Box>
        </div>


    )
}