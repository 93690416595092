import React from 'react';
import { Container, Typography, makeStyles, Box, CircularProgress } from '@material-ui/core';
import ManualOverlay from './ManualOverlay'
import LoadingTemplate from '../general/Loading';

const useStyles = makeStyles((theme) => ({
    descriptionSubject: {
        textAlign: 'justify',
        margin: theme.spacing(0, 0, 1, 0)
    },
    headerSubject: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        textAlign: 'left',
        margin: theme.spacing('auto', 0, 1, 0)
    }
}));

export const TopicBar = ({
    subject,
    topic,
    topicId,
    examples,
    useranswers,
}) => {
    const classes = useStyles();
    if (topic) {
        return (
            <div>
                <Container >
                    <Box id='header' display='flex' flexWrap='nowrap' >
                        <Box id='headerText' padding={1} flexGrow={1}>
                            <Typography variant="h3" className={classes.headerSubject}>{topic.name}</Typography>
                            <Typography variant="body1" className={classes.descriptionSubject}>{topic.description}</Typography>
                        </Box>
                        <Box id='headerProgress' display={{ xs: 'none', sm: 'flex' }} flexDirection='column' justifyContent="center" padding={1}>
                            <Box position="relative" display="inline-block">
                                <Box top={0} left={0} bottom={0} right={0} position="absolute">
                                    <CircularProgress
                                        style={{ color: "#f5f5f5" }}
                                        size={130}
                                        variant="static"
                                        value={100}
                                    />
                                </Box>
                                <CircularProgress color="secondary" size={130} variant="static" value={examples && useranswers ? Math.floor(Object.keys(useranswers).length / examples.length * 100) : 0} />
                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="h5" component="div" color="secondary">{`${examples && useranswers ? Math.floor(Object.keys(useranswers).length / examples.length * 100) : 0}%`}</Typography>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                    <Box>
                            {subject !== 'matematicas' ? null : <ManualOverlay topic={topicId} title={topic.name} />}
                        </Box>
                </Container>
                <Box bgcolor="secondary.light" color="white" marginTop={2} marginBottom={2}>
                    <Container>
                        <Typography variant='button'>{topic.quote}</Typography>
                    </Container>
                </Box>

                

            </div>

        )
    } else {
        return (
            <LoadingTemplate />
        )
    }

}

export default TopicBar;
