import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, makeStyles, Box, Card, CardMedia, CardContent, ButtonBase, CircularProgress } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
  cover: {
    width: 100,
    minWidth: 50,
  },
  number: {
    color: 'white',
    fontWeight: 600,
    // fontSize: '5vw',
    textAlign: 'center'

  }
}));

const TopicCard = ({ topic, subject, totalAnswers }) => {
  const classes = useStyles();
  return (
    <ButtonBase component={NavLink} to={'/' + subject + '/' + topic.id} style={{
      width: '100%', height: '100%'
    }}>
      <Card className={classes.root} >
        <CardMedia className={classes.cover}>
        <Box bgcolor="secondary.main" minWidth='60px' width='100%' height='100%' >
              <Typography className={classes.number} variant="h3" align='left'>
                {topic.index}.
            </Typography>
            </Box>
        </CardMedia>
        <div className={classes.details}>
          <CardContent className={classes.content} >
            <Box flexGrow={1} display='flex' flexDirection='column' justifyContent="center">
              <Typography variant={'h4'} align='left' gutterBottom={false}>
                {subject==='matematicas'? topic.name: 'Lectura: '+topic.title}
              </Typography>
            </Box>
            <Box display={{ xs: 'none', sm: 'flex' }} flexDirection='column' justifyContent="center">
              <Box position="relative" display="inline-block">
                <Box top={0} left={0} bottom={0} right={0} position="absolute">
                  <CircularProgress
                    style={{ color: "#f5f5f5" }}
                    size={80}
                    variant="static"
                    value={100}
                  />
                </Box>
                <CircularProgress color="secondary" size={80} variant="static" value={topic.numberOfQuestions && totalAnswers ? Math.floor((totalAnswers/topic.numberOfQuestions)*100) : 0} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="h6" component="div" color="secondary">{`${topic.numberOfQuestions && totalAnswers ? Math.floor((totalAnswers/topic.numberOfQuestions)*100) : 0}%`}</Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </div>

      </Card>
    </ButtonBase>


  )
}

export default TopicCard