import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, makeStyles,useTheme,useMediaQuery } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import Contact from '../general/Contact'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    linkButton: {
        textTransform: 'none'
    }
}));

function SingnedOutLinks() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const [dialogIsOpen, setDialogIsOpen] = React.useState(false)

    // const openDialog = () => setDialogIsOpen(true)

    // const closeDialog = () => setDialogIsOpen(false)

    return (
        <div className={classes.root}>
            {/* <Button className={classes.linkButton} color="inherit" type="button" endIcon={<ContactSupportIcon />} onClick={openDialog}  >Contacto </Button> */}
            <Button className={classes.linkButton} color="inherit" type="button" variant="outlined" endIcon={<LockOpenIcon />} component={NavLink} to='/iniciarsesion'  size={isMobile ? "small" : "medium"}>Iniciar Sesión</Button>
            {/* <Contact open={dialogIsOpen} onClose={closeDialog} /> */}
        </div>
    )
}

export default SingnedOutLinks;