import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
  palette: {
    primary: { main: '#04346D', dark:'#000e41', light:'#425d9c' },
    secondary: { main: '#5765EB', dark:'#003bb8', light:'#9093ff' },
    info: {main: "#45cafb", dark: "#0099c8", light:"#85fdff"}
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif"
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 4,
        height: 7
      },
      bar1Determinate: {
        borderRadius: 4
      },
      colorPrimary: {
        backgroundColor: "#f5f5f5"
      }
    },
    MuiCircularProgress: {
      circle: {
        strokeLinecap: "round",
        strokeWidth: 2.8
      }
    }
  }
})
export default theme