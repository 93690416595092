import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
// import Contact from '../general/Contact'
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection:'',
        '& > *': {
            margin: theme.spacing(1),
            
        }
    },
    linkButton: {
        textTransform: 'none'
    },
}));

function SignedInLinks(props) {
    const classes = useStyles();
    // const [dialogIsOpen, setDialogIsOpen] = React.useState(false)

    // const openDialog = () => setDialogIsOpen(true)

    // const closeDialog = () => setDialogIsOpen(false)
    return (
        <div className={classes.root}>
            {/* <Button className={classes.linkButton} color="inherit" type="button" endIcon={<ContactSupportIcon />} onClick={openDialog}  >Contacto </Button> */}
            <Button className={classes.linkButton} color="secondary" type="button" endIcon={<LockIcon />} variant="contained" component={NavLink} to='/' onClick={props.signOut} >Cerrar Sesión </Button>
            {/* <Avatar className={classes.orange}>OP</Avatar> */}
            {/* <Contact open={dialogIsOpen} onClose={closeDialog} /> */}
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
      signOut: () => dispatch(signOut())
    }
  }
  
  export default connect(null, mapDispatchToProps)(SignedInLinks)