import React from 'react'
import { Route, Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar, Container, makeStyles, Typography, Breadcrumbs, Link } from '@material-ui/core';
import { connect } from 'react-redux'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(1)
  },
  header: {
    flexGrow: 1,
  },
  breadcrumbs: {
    color: "white",
    textTransform: "capitalize"
  }
}));

function SimpleBreadcrumbs() {
  const classes = useStyles();
  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split("/").filter(x => x);
        return (
          <Breadcrumbs className={classes.breadcrumbs} separator={<NavigateNextIcon fontSize="small" />}>
            <Link component={RouterLink} color="inherit" to="/">
              Inicio
              </Link>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;
              const names = {
                'analitico': 'Pensamiento Analítico', 'lengua': 'Lengua escrita', 'matematicas': 'Matemáticas', 'aritmetica': 'Aritmética', 'algebra': 'Álgebra',
                'geometria': 'Geometría Analítica', 'trigonometria': 'Trigonometría', 'probabilidad': 'Probabilidad y estadística',
                'Lectura1': 'Lectura 1', 'Lectura2': 'Lectura 2', 'Lectura3': 'Lectura 3', 'Lectura4': 'Lectura 4', 'Lectura5': 'Lectura 5',
              }
              const labelText = names[value] ? names[value] : value

              if (index > 1) {
                return null;
              }
              return last ? (
                <Typography className={classes.breadcrumbs} key={to}>
                  {labelText}
                </Typography>
              ) : (
                  <Link className={classes.breadcrumbs} component={RouterLink} to={to} key={to}>
                    {labelText}
                  </Link>
                );
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
}

const Functionbar = (props) => {
  const { auth } = props;
  const classes = useStyles();
  return (
    <div id="Functionbar"  >
      <AppBar position="relative" color="secondary">
        <Container>
          <Toolbar variant="dense">
            {auth.uid ?
                  <SimpleBreadcrumbs />
              :
              <Typography variant="h6" align="right" className={classes.header}>
                ¡Bienvenido a Admissio!
                        </Typography>}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps)(Functionbar)