import React from 'react';
import ReactMarkdown from 'react-markdown'
import {
    Icon,
    makeStyles,
    Avatar,
    Box,
    Typography,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: '100%',
        verticalAlign: 'center',
        minHeight: '120px',
        // padding: theme.spacing(0, 10, 0, 10)

    },
    avatar: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        color: "white",
        backgroundColor: theme.palette.info.main,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),

    },
    quoteleft: {
        fontStyle: 'italic',
        textAlign: 'left',
        padding: 0,
        margin: 0
    },
    quoteright: {
        fontStyle: 'italic',
        textAlign: 'right'
    },
    quotecenter: {
        fontStyle: 'italic',
        textAlign: 'center'
    }
}));

export const QuoteItem = ({
    quote,
    name,
    index,
    career,
    icon,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div className={classes.root}>
            {/* <Container margin={1} maxWidth={isMobile ? false : 'lg'}> */}
            <Box display={isMobile ? 'block' : 'flex'} justifyContent='center'>
                {index % 2 ? null :
                    <div hidden={isMobile ? true : false}>
                        <Avatar className={classes.avatar} sizes="large" >
                            <Icon style={{ fontSize: 70 }}>{icon}</Icon>
                        </Avatar>
                    </div>}
                <Box display='flex' flexDirection='column' justifyContent='center'>
                    <Typography className={isMobile ? 'center' : index % 2 ? classes.quoteright : classes.quoteleft} gutterBottom={false}>
                        <ReactMarkdown disallowedTypes={['paragraph']} unwrapDisallowed>{quote}</ReactMarkdown>
                    </Typography>
                    <Typography className={isMobile ? 'center' : index % 2 ? classes.quoteright : classes.quoteleft} color='textSecondary' gutterBottom={false}>
                        {name} ({career})</Typography>
                </Box>
                {index % 2 ?
                    <div hidden={isMobile ? true : false}>
                        <Avatar className={classes.avatar} sizes="large" >
                            <Icon style={{ fontSize: 70 }}>{icon}</Icon>
                        </Avatar>
                    </div> : null}

            </Box>
            {/* </Container> */}

        </div>
    );
}