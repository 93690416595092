import React from "react";
import { Typography, makeStyles, Box, Grid } from "@material-ui/core";
import Iframe from "react-iframe";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  info: {
    textAlign: "justify",
  },
  ratioWrapper: {
    width: "100%",
    height: 0,
    paddingBottom: "56.25%",
    position: "relative",
  },
  videoWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  video: {
    width: "100%",
    height: "100%",
  },
}));

export default function Info() {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <Typography variant="h6" color="primary" align="left">
          <strong>¡Gracias por elegir a Admissio!</strong>
        </Typography>
        <Typography variant="body1" className={classes.info}>
          La plataforma está diseñada para llevarte de la mano por todos los
          temas a evaluar en tu examen. Te invitamos a revisar el video para
          tener más detalles de la dinámica de cada sección.
        </Typography>
        <Typography variant="body1" className={classes.info}>
          A continuación te presentamos aspectos importantes del examen de
          ingreso a la Educación Superior de la UABC que estás por aplicar:
        </Typography>
        <Typography variant="body2" className={classes.info}>
          <li>
            Cuatro áreas:{" "}
            <strong>
              Pensamiento matemático, pensamiento analítico, estructura de la
              lengua y comprensión lectora.
            </strong>
            .
          </li>
          <li>
            Énfasis en la aplicación de los conocimientos y habilidades
            adquiridos durante preparatoria.
          </li>
          <li>
            Tiempo de aplicación: <strong>3 horas.</strong>
          </li>
          <li>
            <strong>Número total de preguntas: 110, </strong>25 de cada área,
            más 10 preguntas de control (sin valor).
          </li>
          <li>
            Todas las preguntas se califican de la misma manera:{" "}
            <strong>
              un punto por cada respuesta correcta y cero puntos por cada
              respuesta incorrecta o en blanco
            </strong>
            .
          </li>
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box className={classes.ratioWrapper}>
          <Box className={classes.videoWrapper}>
            <Iframe
              url="https://www.youtube.com/embed/Dxx2xVbYJpc?controls=0&rel=0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              className={classes.video}
              frameBorder="0"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
