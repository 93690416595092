export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err });
    });

  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS' })
    });
  }
}

export const addData = (userData) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const path = 'users/' + firebase.auth().currentUser.uid
    var user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      userData.currentPassword
    );

    user.reauthenticateWithCredential(credential).then(function () {
      user.updatePassword(userData.newPassword).then(function () {
        firestore.doc(path).set({
          fname: userData.firstName,
          lname: userData.lastName,
          defaultPassword: false
        })
        dispatch({ type: 'SAVE_DATA_SUCCESS' });
      }).catch(function (err) {
        dispatch({ type: 'SAVE_DATA_ERROR' }, err);
      });
    }).catch(function (err) {
      dispatch({ type: 'SAVE_DATA_ERROR' }, err);
    })
  }
};

