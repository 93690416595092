import React from 'react'
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Container, Box, makeStyles, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux'
import { compose } from 'redux'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import ChangeInfo from '../auth/ChangeInfo'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    menuButton: {
        padding:0
    },
    title: {
        flexGrow: 1,
    },
}));

const Navbar = (props) => {
    const { auth } = props;
    const classes = useStyles();
    const links = auth.uid ? <SignedInLinks /> : <SignedOutLinks />;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div id="Navbar" className={classes.root}>
            <ChangeInfo/>
            <AppBar position="sticky">
                <Container display="inline">
                    <Box component="div" >
                        <Toolbar disableGutters={isMobile ? true : false} >
                            <IconButton aria-label="delete" component={NavLink} to='/' className={classes.menuButton}>
                                <svg height="40px" width={isMobile ? '38px' : '100%'}>
                                    <image href="https://firebasestorage.googleapis.com/v0/b/admissio-8d4a5.appspot.com/o/Logo%2FSVG%2FRecurso%205.svg?alt=media&token=ab5e196d-8e63-4e01-a748-9f9e6fcd6d07" />
                                </svg>
                            </IconButton>
                            <Box className={classes.title}/>
                            {links}
                        </Toolbar>
                    </Box>
                </Container>
            </AppBar>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    }
}

export default compose(
    connect(mapStateToProps),
)(Navbar)