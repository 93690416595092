import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Submit({actionSubmit}, answerList) {
  const [open, setOpen] = React.useState(false);
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    actionSubmit();
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary"  onClick={handleClickOpen}>
      Terminar examen
      </Button>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">{"¿Estas seguro que deseas terminar el examen?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Una vez terminado no podras editar tus respuestas y obtendras tu puntaje final
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button autoFocus onClick={handleAccept} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Submit;