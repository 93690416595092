import React, { Component } from 'react'
import { Button, Box, TextField, Typography, Grid, CssBaseline, withStyles, Avatar, Paper } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 20
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});


class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state)
    }


    render() {
        const { auth } = this.props;
        if (auth.uid) return <Redirect to='/' />
        const { classes } = this.props;
        const { authError } = this.props;
        const isInvalid = this.state.password === '' || this.state.email === '';
        return (
            <div  id="SignIn" className="Content">
                <Grid className={classes.root}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    >
                    <Paper >
                        <Box padding={2}>
                            <CssBaseline />
                            <div className={classes.paper}>
                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5" >
                                    Iniciar sesión
                                </Typography>
                                <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Correo Electrónico"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        onChange={this.handleChange}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Constraseña"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={this.handleChange}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={isInvalid}
                                    >
                                        Entrar
                                    </Button>
                                    {authError ? <Typography variant="body2" color='error'>{authError.message}</Typography> : null}
                                </form>
                            </div>
                        </Box>
                    </Paper>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default compose(withStyles(useStyles), connect(mapStateToProps, mapDispatchToProps))(SignIn)