import React from 'react';
import { NavLink } from 'react-router-dom';
import { ButtonBase, Card, Typography, makeStyles, Box, Avatar } from '@material-ui/core'
import LensIcon from '@material-ui/icons/Lens';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  iconSuccess: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  iconPending: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  iconError: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));

const ExampleCard = ({ example, index, subject, topic, ansFound }) => {
  const classes = useStyles();
  return (
    <ButtonBase component={NavLink} to={'/' + subject + '/' + topic + '/' + index} style={{ width: '100%', height: '100%' }}>
      <Card className={classes.root} square>
        <Box display="flex" flexDirection='column' p={2} width="100%">
          <Box
            display="flex"
            flexWrap="nowrap"
            flexDirection='row'>
            <Box width="100%">
              {subject !== 'matematicas' && subject !== 'analitico' ?
                <Typography component="h6" variant="h6" align='left' gutterBottom >
                  Pregunta {index}
                </Typography> :
                <Typography component="h6" variant="h6" align='left' gutterBottom >
                  Ejercicio {index}
                </Typography>}
            </Box>
            <Box flexShrink={0}>
              {!ansFound ?
                <Avatar className={classes.iconPending}>
                  <LensIcon fontSize='small' />
                </Avatar> :
                ansFound['answer'] === example.ans ?
                  <Avatar className={classes.iconSuccess}>
                    <CheckIcon />
                  </Avatar> :
                  <Avatar className={classes.iconError}>
                    <ClearIcon fontSize='small' />
                  </Avatar>
              }
            </Box>
          </Box>
          {subject !== 'matematicas' && subject !== 'analitico' ?
            null :
            <Box display="flex" flexWrap="nowrap" flexDirection="row" width='100%' >
              <Typography color='textSecondary' variant='overline'>Dificultad: </Typography>
              {example.dificulty === 1 ?
                <Box paddingTop='5px' paddingLeft={1}><LensIcon color='action' style={{ fontSize: 10 }} /></Box> :
                example.dificulty === 2 ?
                  <Box paddingTop='5px' paddingLeft={1}><LensIcon color='action' style={{ fontSize: 10 }} /><LensIcon color='action' style={{ fontSize: 10 }} /></Box> :
                  <Box paddingTop='5px' paddingLeft={1}><LensIcon color='action' style={{ fontSize: 10 }} /><LensIcon color='action' style={{ fontSize: 10 }} /><LensIcon color='action' style={{ fontSize: 10 }} /></Box>}
            </Box>}
        </Box>
      </Card>
    </ButtonBase>
  )
}

export default ExampleCard