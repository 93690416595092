import React from 'react'
import { IconButton, Container, Box, Typography } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';


const Bottombar = () => {
    return (
        <Container>
            <Box display="flex" justifyContent="center" style={{ position: "relative", bottom: "0px", marginTop: "20px" }}>
                <IconButton color="inherit" target="_blank" href="https://wa.me/5216641744903?text=¡Me%20interesa%20saber%20más%20de%20Admissio!">
                    <WhatsAppIcon fontSize="large" />
                </IconButton>
                <IconButton color="inherit" target="_blank" href="https://www.facebook.com/Admissio-by-Jenny-Morales-100586108580760">
                    <FacebookIcon fontSize="large" />
                </IconButton>
                <IconButton color="inherit" target="_blank" href="mailto:info@admissio.mx">
                    <EmailIcon fontSize="large" />
                </IconButton>
            </Box>
            <Typography variant="caption" fontSize='.5rem' >Admissio by Jenny Morales - 2020</Typography>
        </Container>
    )
}

export default Bottombar