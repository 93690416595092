import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Button,
    ButtonGroup,
    Box,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextItem } from '../general/text.item';
import { ImageItem } from '../general/image.item';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { InlineMath, BlockMath } from 'react-katex';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardStyle: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center'
    },
    media: {
        height: 'auto',
        maxHeight: 300,
        width: '100%',
        objectFit: 'contain',
    },
    formControl: {
        margin: theme.spacing(3),
    },

    buttonError: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        }
    },
    buttonSuccess: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        }
    },
    buttonWaiting: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    extendedIcon: {
        marginLeft: theme.spacing(1),
    },
    BlockMath: {
        fontSize: '1.5rem'
    }
}))



export const QuestionItem = ({
    question,
    textList,
    answerValue,
    onSubmit,
    index,
    totalQuestions,
    handleRadioChange,
    progress,
    onClickPrevious,
    onClickNext
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={2} alignItems="stretch" justify="center">
                <Grid item xs={12}>
                    <Typography variant="overline" color="textSecondary" component="p">{question.subject+" - Pregunta  " + index + " de " + totalQuestions}</Typography>
                </Grid>
                {
                    question.textId && textList ?
                        <Grid item xs={8}>
                            <TextItem text={textList[question.subject+question.textId]} />
                        </Grid>
                        :
                        null
                }
                <Grid item xs height='100%'>
                    <Card className={classes.cardStyle} >
                        <CardContent >
                            <Box display='flex' flexDirection='column'>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <Typography variant="h6" component="p">
                                        {Array.isArray(question.question) ?
                                            question.question.map((value, index) => { return value.isFormula ? <InlineMath key={index}>{value.text}</InlineMath> : value.text }) : question.question}
                                    </Typography>
                                    {
                                        typeof question.img != 'undefined' && question.img ?
                                            <ImageItem imgName={question.img} />
                                            :
                                            null
                                    }
                                    <RadioGroup aria-label="options" name="options" value={answerValue} onChange={(value) => handleRadioChange(value.target.value)}>
                                        <FormControlLabel value="A" control={<Radio />} label={question.ansFormula ? <BlockMath>{question.op_A}</BlockMath> : <Typography variant="body2" align='left' gutterBottom={true}>{question.op_A}</Typography>} />
                                        <FormControlLabel value="B" control={<Radio />} label={question.ansFormula ? <BlockMath>{question.op_B}</BlockMath> : <Typography variant="body2" align='left' gutterBottom={true}>{question.op_B}</Typography>} />
                                        <FormControlLabel value="C" control={<Radio />} label={question.ansFormula ? <BlockMath>{question.op_C}</BlockMath> : <Typography variant="body2" align='left' gutterBottom={true}>{question.op_C}</Typography>} />
                                        <FormControlLabel value="D" control={<Radio />} label={question.ansFormula ? <BlockMath>{question.op_D}</BlockMath> : <Typography variant="body2" align='left' gutterBottom={true}>{question.op_D}</Typography>} />
                                    </RadioGroup>

                                </FormControl>
                            </Box>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup variant="contained" color="primary">
                        {index === 1 || index === "1" ?
                            null :
                            <Button
                                onClick={() => onClickPrevious()}
                                startIcon={<ArrowBackIosIcon />}>
                                Anterior
                    </Button>
                        }
                        {index === totalQuestions || index === totalQuestions.toString() ?
                            <Button
                                onClick={() => onSubmit()}
                                endIcon={<CheckIcon />}>
                                Ver resultados
                        </Button> :
                            <Button
                                onClick={() => onClickNext()}
                                endIcon={<ArrowForwardIosIcon />}>
                                Siguiente
                            </Button>
                        }
                    </ButtonGroup>
                </Grid>
            </Grid>
        </div >
    );
}