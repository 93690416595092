import React, { Component } from 'react';
import { Button, Box, TextField, withStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase'
import { addData } from '../../store/actions/authActions';
import { compose } from 'redux'
import LockIcon from '@material-ui/icons/Lock';
import { signOut } from '../../store/actions/authActions'
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 16,
  },
  formControl: {
    margin: 16,
  }
});

class ChangeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      currentPassword: '',
      newPassword: '',
      open: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addData(this.state);
  }

  handleClose = (e) => {
    this.props.signOut();
  }

  render() {
    const { auth, userData, authError } = this.props;
    const classes = styles();
    const oldpassInvalid = this.state.currentPassword.length < 6 && this.state.currentPassword !== '';
    const newpassInvalid = this.state.newPassword.length < 6 && this.state.newPassword !== '';
    const isInvalid = this.state.currentPassword === '' || this.state.newPassword === '' || this.state.firstName === '' || this.state.lastName === '';
    if (auth.uid && (userData === null || (userData && userData.defaultPassword))) {
      return (
        <div>
          <Dialog aria-labelledby="customized-dialog-title" open={true}>
            <DialogTitle align='center' id="form-dialog-title">¡Bienvenido a Admissio!</DialogTitle>
            <DialogContent>
              <Box display='flex' justifyContent='center' m={1}>
                <img height="100px"
                  alt="icono admissio"
                  src={require('./../static/admissio_a.png')} />
              </Box>
              <DialogContentText align='center'>
                Confirma tus datos para completar tu registro ¡bienvenido!
          </DialogContentText>
              <form noValidate className={classes.formControl}>
                <TextField
                  onChange={this.handleChange}
                  margin="dense"
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="Nombre(s)"
                  autoFocus
                />
                <TextField
                  onChange={this.handleChange}
                  margin="dense"
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Apellido(s)"
                  name="lastName"
                  autoComplete="lname"
                />
                <TextField
                  onChange={this.handleChange}
                  margin="dense"
                  variant="outlined"
                  required
                  fullWidth
                  name="currentPassword"
                  error={oldpassInvalid}
                  label="Contraseña actual"
                  type="password"
                  id="currentPassword"
                  autoComplete="current-password"
                />
                <TextField
                  onChange={this.handleChange}
                  margin="dense"
                  variant="outlined"
                  required
                  fullWidth
                  name="newPassword"
                  error={newpassInvalid}
                  label="Contraseña nueva"
                  type="password"
                  id="newPassword"
                  autoComplete="current-password"
                />
              </form>

            </DialogContent>
            {authError ? <Typography variant="body2" color='error'>{authError.message}</Typography> : null}
            <DialogActions >
            <Button onClick={this.handleClose}  color="primary" endIcon={<LockIcon />}  component={NavLink} to='/'>Cerrar Sesión </Button>

              <Button onClick={this.handleSubmit} disabled={isInvalid} color="primary">
                Registrar
          </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else return null;

  }
}

const mapStateToProps = (state) => {
  const userData = state.firestore.data.userData ? state.firestore.data.userData : null;
  return {
    userData: userData,
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addData: (data) => dispatch(addData(data)),
    signOut: () => dispatch(signOut())
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [{
    collection: 'users',
    doc: props.auth.uid,
    storeAs: 'userData'
  }
  ])
)(ChangeInfo)