import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, makeStyles, Box } from '@material-ui/core';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom';
import TopicBar from './TopicBar'
import ExampleCard from './ExampleCard';
import { TextItem } from '../example/text.item'
import LoadingTemplate from '../general/Loading';

const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.5em'
        },
        '*::-webkit-scrollbar-track': {
            backgroundColor: '#f5f5f5',
            borderRadius: '20px',
            border: '1px solid white'
            // '-webkit-box-shadow': 'inset 0 0 6px rgba(190,190,190,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '20px',
            border: '1px solid #f5f5f5'
        }
    },
    scrollbox: {
        overflowY: 'auto',
        maxHeight: '68vh'
    }
}));

const Topic = (props) => {
    const classes = useStyles();
    const { topic, auth, examples, subject, topicId, useranswers } = props;
    if (!auth.uid) return <Redirect to='/' />
    if (topic) {
        return (
            <div id="Topic" >
                <TopicBar examples={examples} useranswers={useranswers} topic={topic} subject={subject} topicId={topicId} />
                <Container >
                    <Grid container spacing={2} alignItems="stretch" justify="center">
                        {
                            topic && subject !== 'matematicas' && subject !== 'analitico' ?
                                <Grid item xs={8}>
                                    <TextItem text={topic} />
                                </Grid>
                                :
                                null
                        }
                        <Grid item xs height='100%'>
                                {subject !== 'matematicas' && subject !== 'analitico' ?
                                    <Box className={classes.scrollbox}>
                                        {examples && examples.map((example, index) => {
                                            return (
                                                <Box m={1} key={'example' + index}>
                                                    <ExampleCard
                                                        example={example}
                                                        index={index + 1}
                                                        topic={topicId}
                                                        subject={subject}
                                                        ansFound={useranswers && useranswers[example.id] ? useranswers[example.id] : null} />
                                                </Box>
                                            )
                                        })}
                                    </Box> :
                                    <Grid container spacing={2}>
                                        {examples && examples.map((example, index) => {
                                            return (
                                                <Grid item key={index} sm={subject !== 'matematicas' && subject !== 'analitico' ? 12 : 4} xs={12}>
                                                    <ExampleCard
                                                        example={example}
                                                        key={index}
                                                        index={index + 1}
                                                        topic={topicId}
                                                        subject={subject}
                                                        ansFound={useranswers && useranswers[example.id] ? useranswers[example.id] : null} />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    } else {
        return (
            <LoadingTemplate />
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    const topic = state.firestore.data.topic ? state.firestore.data.topic : null;
    const examples = state.firestore.data.examples ? state.firestore.ordered.examples : null;
    const useranswers = state.firestore.data.useranswers ? state.firestore.data.useranswers : null;
    return {
        topic: topic,
        topicId: ownProps.match.params.topic,
        subject: ownProps.match.params.subject,
        useranswers: useranswers,
        examples: examples,
        auth: state.firebase.auth
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => {
        var collection = [{
            collection: 'subject',
            doc: props.match.params.subject,
            subcollections: [
                { collection: 'topics', doc: props.match.params.topic }
            ],
            storeAs: 'topic'
        },
        {
            collection: 'users',
            doc: props.auth.uid,
            subcollections: [
                {
                    collection: props.match.params.subject,
                    doc: props.match.params.topic,
                    subcollections: [
                        { collection: 'answers' }
                    ]
                }
            ],
            storeAs: 'useranswers'
        }]
        if (props.match.params.subject === 'matematicas' || props.match.params.subject === 'analitico') {
            collection.push({
                collection: 'subject',
                doc: props.match.params.subject,
                subcollections: [
                    {
                        collection: 'topics', doc: props.match.params.topic,
                        subcollections: [
                            {
                                collection: 'examples',
                                orderBy: ['dificulty'],
                            }
                        ]
                    }
                ],
                storeAs: 'examples'
            })
        } else {
            collection.push({
                collection: 'subject',
                doc: props.match.params.subject,
                subcollections: [
                    {
                        collection: 'topics', doc: props.match.params.topic,
                        subcollections: [
                            {
                                collection: 'examples'
                            }
                        ]
                    }
                ],
                storeAs: 'examples'
            })
        }
        return collection
    },
    )

)(Topic)