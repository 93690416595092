import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ManualOverlay(topic) {
    // const { path } = this.props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    try {
        var path = require('../../docs/' + topic.topic + '.pdf');

        return (
            <Box>
                <Button height='100%' variant="outlined" color="secondary" onClick={handleClickOpen} endIcon={<FindInPageIcon />}>
                    Ver manual
            </Button>
                <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar color='secondary' className={classes.appBar}>
                        <Toolbar>
                            <Typography edge="start" variant="h6" className={classes.title}>
                                Manual {topic.title}
                            </Typography>
                            <Button autoFocus color="inherit" onClick={handleClose} aria-label="close">
                                Cerrar <CloseIcon />
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Box height='100%'>
                        <object
                            data={path}
                            type="application/pdf"
                            width="100%"
                            height='100%'
                        >
                            <br />
                            <a href={path} id="enlaceDescargarPdf"
                                download="ReactJS.pdf"
                            >Tu dispositivo no puede visualizar los PDF, da click aquí para descargarlo</a>
                        </object>
                    </Box>

                </Dialog>
            </Box>
        )
    }
    catch {
        return null;
    }
}
