import React from 'react'
import { NavLink } from 'react-router-dom';
import { Grid, Paper, Typography, makeStyles, Box, Button, ButtonBase } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    buttonSubject: {
        // marginRight: theme.spacing(2),
        margin: theme.spacing('auto', 0, 0, 0),
        backgroundColor: theme.palette.secondary.light,
        color: 'white'

    },
    descriptionSubject: {
        align: 'left',
        margin: theme.spacing(0, 0, 1, 0)
    },
    headerSubject: {
        textTransform: 'uppercase',
        fontWeight: 700,
        color: theme.palette.primary.main,
        textAlign: 'left',
        margin: theme.spacing('auto', 0, 1, 0)
    },
    header: {
        textTransform: 'uppercase',
        fontWeight: 800,
        color: theme.palette.primary.main,
        textAlign: 'center',
        margin: theme.spacing('auto', 0, 1, 0)
    },
    box: {
        display: 'flex',
        flexWrap: 'nowrap',
    }
}));

export default function Subjects() {
    const classes = useStyles();
    return (
        <Box paddingTop={2}>
            <Typography variant='h5' className={classes.header}>Materias disponibles</Typography>
            <Grid container className={classes.root} spacing={2} >
                <Grid item xs={12} md={3}>
                    <ButtonBase component={NavLink} to='/matematicas'>
                        <Paper square >
                            <Box className={classes.box} flexDirection="row">
                                <img height='230px'
                                    alt="Pensamiento matemático"
                                    src={require('./../static/matematicas.png')} />
                                <Box className={classes.box} flexDirection="column" p={1} justifyContent="center">
                                    <Typography className={classes.headerSubject} variant='h6' gutterBottom>Pensamiento matemático</Typography>
                                    <Typography variant='caption' align='left' className={classes.descriptionSubject} gutterBottom>Aprende y repasa temas como <strong>Algebra, Aritmética, Factorización, Despeje</strong> y más.</Typography>
                                    <Button size="small" className={classes.buttonSubject} variant='contained' >Ver contenido</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </ButtonBase>

                </Grid>
                <Grid item xs={12} md={3}>
                    <ButtonBase component={NavLink} to='/analitico' disabled={false}>
                        <Paper square >
                            <Box className={classes.box} flexDirection="row">
                                <img height='230px'
                                    alt="Pensamiento"
                                    src={require('./../static/pensamiento.png')} />
                                <Box className={classes.box} flexDirection="column" p={1} justifyContent="center">
                                    <Typography className={classes.headerSubject} variant='h6' gutterBottom>pensamiento analítico</Typography>
                                    <Typography variant='caption'  align='left' className={classes.descriptionSubject} gutterBottom>Interpretación de <strong>relaciones lógicas, analogías, sucesiones </strong>y más...</Typography>
                                    <Button disabled={false} size="small" className={classes.buttonSubject} variant='contained'>Ver contenido</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} md={3}>
                    <ButtonBase component={NavLink} to='/lengua'>
                        <Paper square >
                            <Box className={classes.box} flexDirection="row">
                                <img height='230px'
                                    alt="Español"
                                    src={require('./../static/espanol.png')} />
                                <Box className={classes.box} flexDirection="column" p={1} justifyContent="center">
                                    <Typography className={classes.headerSubject} variant='h6' gutterBottom>estructura de la lengua</Typography>
                                    <Typography variant='caption' align='left' className={classes.descriptionSubject} gutterBottom><strong>Categorias gramaticales, reglas ortográfica, lógica textual</strong> y más...</Typography>
                                    <Button size="small" className={classes.buttonSubject} variant='contained'>Ver contenido</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </ButtonBase>
                </Grid>
                <Grid item xs={12} md={3}>
                    <ButtonBase component={NavLink} to='/lectura'>
                        <Paper square >
                            <Box className={classes.box} flexDirection="row">
                                <img height='230px'
                                    alt="Lectura"
                                    src={require('./../static/lecturas.png')} />
                                <Box className={classes.box} flexDirection="column" p={1} justifyContent="center">
                                    <Typography className={classes.headerSubject} variant='h6' gutterBottom>Comprensión lectora</Typography>
                                    <Typography variant='caption'   align='left' className={classes.descriptionSubject} gutterBottom>Practica la lectura de <strong>comprensión</strong>, refuerza tu capacidad de <strong>relación entre dos textos</strong> y más...</Typography>
                                    <Button size="small" className={classes.buttonSubject} variant='contained'>Ver contenido</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </ButtonBase>
                </Grid>
            </Grid>
        </Box>
    )
}