import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Container, withStyles, LinearProgress, Typography, Box,
} from '@material-ui/core';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { QuestionItem } from './question.item';
import Submit from './Submit'
import { saveExample } from '../../store/actions/exampleActions'
import LoadingTemplate from '../general/Loading';
import Score from './Score';

const styles = (theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.5em'
        },
        '*::-webkit-scrollbar-track': {
            backgroundColor: '#f5f5f5',
            borderRadius: '20px',
            border: '1px solid white'
            // '-webkit-box-shadow': 'inset 0 0 6px rgba(190,190,190,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '20px',
            border: '1px solid #f5f5f5'
        }
    },
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    descriptionSubject: {
        textAlign: 'left',
        margin: theme.spacing(0, 0, 1, 0)
    },
    headerSubject: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        textAlign: 'left',
        margin: theme.spacing('auto', 0, 1, 0)
    }
});


class MainTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questionList: null,
            answerList: {},
            answerValue: null,
            progress: 0,
            score: 0,
            getResult: false,
            currentQuestion: null,
            time: {},
            seconds: 10800,
        }
        this.timer = 0;
        this.myRef = React.createRef();
        this.handleExampleChange = this.handleExampleChange.bind(this);
    }

    componentDidMount() {
        let timeLeft = this.convertSecsToTime(this.state.seconds);
        this.setState({ time: timeLeft });
        this.startTimer();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if ((nextProps.questionList !== prevState.questionList)) {
            return {
                questionList: nextProps.questionList,
                currentQuestion: nextProps.questionList ? nextProps.questionList[0] : null,
            }
        }
        return null;
    }

    convertSecsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));
        let minutesDivisor = secs % (60 * 60);
        let minutes = Math.floor(minutesDivisor / 60);
        let secondsDivisor = minutesDivisor % 60;
        let seconds = Math.ceil(secondsDivisor);

        // Format hours, minutes, and seconds to always have two digits
        let formattedMinutes = ("0" + minutes).slice(-2);
        let formattedSeconds = ("0" + seconds).slice(-2);

        return {
            "h": hours,
            "m": formattedMinutes,
            "s": formattedSeconds
        };
    }

    secondsCountDown = () => {
        let { seconds } = this.state;
        --seconds;
        this.setState({
            time: this.convertSecsToTime(seconds),
            seconds
        });

        if (seconds === 0) {
            clearInterval(this.timer);
            this.onSubmit()
        }
    }

    startTimer = () => {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.secondsCountDown, 1000);
        }
    }

    handleExampleChange(props) {
        this.setState({
            answerValue: props.answerValue,
            status: null,
            helperText: 'Revisar'
        });
    }

    handleCurrentQuestion = () => {
        const { questionList, progress, answerList } = this.state;
        let currentQuestion = questionList[progress];
        let ans = answerList[currentQuestion.id] === undefined ? null : answerList[currentQuestion.id]['actual'];
        this.setState({ currentQuestion, answerValue: ans });
    }

    handleRadioChange = (value) => {
        let { answerList, currentQuestion } = this.state;
        if (value) {
            answerList[currentQuestion.id] = {actual: value, expected:currentQuestion.ans}
        }
        this.setState({
            answerValue: value,
            answerList,
        });
    }

    onClickNext = () => {
        this.setState({
            progress: this.state.progress + 1
        }, () => {
            this.handleCurrentQuestion();
        });
        
    }

    onClickPrevious = () => {
        this.setState({
            progress: this.state.progress - 1
        }, () => {
            this.handleCurrentQuestion();
        });
    }

    onSubmit = (event) => {
        let { answerList } = this.state;
        var score = 0;
        for (const [ans, values] of Object.entries(answerList)){
            if (values.actual===values.expected){
                score = score +1;
            }
        }
        score = 700 + Math.floor(score * 6);
        this.setState({
            getResult: true,
            score

        });
    }

    render() {
        const { classes } = this.props;
        const { auth, lecturaList } = this.props;
        const { questionList, answerValue, progress, seconds, currentQuestion, getResult, score } = this.state;
        const normalise = value => 100 - (value) * 100 / (10800);
        if (!auth.uid) return <Redirect to='/' />
        if (currentQuestion) {
            return (
                <Box style={{ height: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    {getResult? <Score score = {score}/>: null}
                    <LinearProgress variant="determinate" color="secondary" value={normalise(seconds)} style={{ height: 10, borderRadius: 0 }} />
                    <Box display='flex'>
                        <Submit actionSubmit={this.onSubmit}/>
                        <Typography variant="overline" color="textSecondary" style={{ width: 'auto', flexGrow: 1, marginRight: 10, textAlign: "right" }}>
                            {`Tiempo restante ${this.state.time.h}:${this.state.time.m}:${this.state.time.s}`}
                        </Typography>
                    </Box>
                    <div id="Question" className="Content">
                        <Container className={classes.root} >
                            <QuestionItem
                                question={currentQuestion}
                                textList={lecturaList}
                                totalQuestions={questionList.length}
                                index={progress + 1}
                                answerValue={answerValue}
                                onSubmit={this.onSubmit}
                                handleRadioChange={this.handleRadioChange}
                                onClickNext={this.onClickNext}
                                onClickPrevious={this.onClickPrevious}
                                progress={progress}

                            />
                        </Container>
                    </div>
                </Box>


            )
        } else {
            return (
                <LoadingTemplate />
            )
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveExample: (id, answerValue, subject, topic) => dispatch(saveExample(id, answerValue, subject, topic))
    }
}

const mapStateToProps = (state, ownProps) => {
    const questionListLengua = state.firestore.data.questionListLengua ? state.firestore.ordered.questionListLengua : null;
    const questionListMatematicas = state.firestore.data.questionListMatematicas ? state.firestore.ordered.questionListMatematicas : null;
    const questionListLectura = state.firestore.data.questionListLectura ? state.firestore.ordered.questionListLectura : null;
    const questionListAnalitico = state.firestore.data.questionListAnalitico ? state.firestore.ordered.questionListAnalitico : null;
    const questionList = questionListLengua && questionListMatematicas && questionListLectura && questionListAnalitico ? questionListLengua.concat(questionListMatematicas).concat(questionListLectura).concat(questionListAnalitico): null;
    const totalQuestions = questionList ? questionList.length : null;
    const lecturasLectura = state.firestore.data.lecturasLectura ? state.firestore.data.lecturasLectura : null;
    const lecturasLengua = state.firestore.data.lecturasLengua ? state.firestore.data.lecturasLengua : null;
    const lecturas =Object.assign({}, lecturasLectura, lecturasLengua);
    return {
        totalQuestions: totalQuestions,
        questionList: questionList,
        auth: state.firebase.auth,
        lecturaList: lecturas,
    }
}

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
    connect(mapStateToProps),
    firestoreConnect([
    {
        collection: 'subject',
        doc: 'lengua',
        subcollections: [
            {
                collection: 'test',
                orderBy: ['index'],
            }
        ],
        storeAs: 'questionListLengua'
    },
    {
        collection: 'subject',
        doc: 'matematicas',
        subcollections: [
            {
                collection: 'test',
                orderBy: ['index'],
            }
        ],
        storeAs: 'questionListMatematicas'
    },
    {
        collection: 'subject',
        doc: 'lectura',
        subcollections: [
            {
                collection: 'test',
                orderBy: ['index'],
            }
        ],
        storeAs: 'questionListLectura'
    },
    {
        collection: 'subject',
        doc: 'analitico',
        subcollections: [
            {
                collection: 'test',
                orderBy: ['index'],
            }
        ],
        storeAs: 'questionListAnalitico'
    },
    {
        collection: 'subject',
        doc: 'lectura',
        subcollections: [
            {
                collection: 'texts',
            }
        ],
        storeAs: 'lecturasLectura'
    },
    {
        collection: 'subject',
        doc: 'lengua',
        subcollections: [
            {
                collection: 'texts',
            }
        ],
        storeAs: 'lecturasLengua'
    }]
    )

)(MainTest)