import React from 'react';
import { connect } from 'react-redux';
import { Container, Typography, makeStyles, withWidth, isWidthDown } from '@material-ui/core';
import TopicList from './Topic.list';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom';
import LoadingTemplate from '../general/Loading';

const useStyles = makeStyles((theme) => ({
    descriptionSubject: {
        textAlign: 'justify',
        margin: theme.spacing(0, 0, 1, 0)
    },
    headerSubject: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        textAlign: 'left',
        margin: theme.spacing('auto', 0, 1, 0)
    }
}));

const Subject = (props) => {
    const classes = useStyles();
    const subjecId = props.match.params.subject;
    const { subject, topics, auth, answers } = props;
    if (!auth.uid) return <Redirect to='/' />
    if (subjecId === 'analitico') {
        return (
            <Redirect to={'/analitico/analitico'} />
        )
    }
    if (subject) {
        return (
            <div id="Subject">
                <Container>
                    {isWidthDown('sm', props.width)?
                    <Typography variant="h3" className={classes.headerSubject}>{subject.name}</Typography>:
                    <Typography variant="h2" className={classes.headerSubject}>{subject.name}</Typography>}
                    <Typography variant="body1" className={classes.descriptionSubject}>{subject.description}</Typography>
                    {topics ? <TopicList topics={topics} subject={subjecId} answers={answers} /> : null}
                </Container>
            </div>
        )
    } else {
        return (
            <LoadingTemplate/>
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    const subject = state.firestore.data.subject ? state.firestore.data.subject : null;
    const topics = state.firestore.data.topics ? state.firestore.ordered.topics : null;
    const answers = state.firestore.data.useranswers ? state.firestore.data.useranswers : null;
    return {
        subject: subject,
        topics: topics,
        answers: answers,
        auth: state.firebase.auth
    }
}

export default compose(
    withWidth(),
    connect(mapStateToProps),
    firestoreConnect(props => [
        {
            collection: 'subject',
            doc: props.match.params.subject,
            storeAs: 'subject'
        },
        {
            collection: 'subject',
            doc: props.match.params.subject,
            subcollections: [
                {
                    collection: 'topics',
                    orderBy: ['index'],
                }
            ],
            storeAs: 'topics'
        },
        {
            collection: 'users',
            doc: props.auth.uid,
            subcollections: [
                {
                    collection: props.match.params.subject,
                }
            ],
            storeAs: 'useranswers'
        }
    ])

)(Subject)