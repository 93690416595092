import React from 'react';
import {
    Typography, Box, CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
}))

const LoadingTemplate = ({
    text,
}) => {
    const classes = useStyles();

    return (
        <div id="Loading" className="Content">
            <Box className={classes.root}>
                <Box display='flex' flexDirection='column' justifyContent='center' height='100%' >
                    <Box m={2}>
                        <CircularProgress />
                        <Typography variant="h6" color="primary">
                            {text ? text : 'Cargando...'}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default LoadingTemplate
