export const saveExample = (id, answerValue, subject, topic) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
      // make async call to database
      const firestore = getFirestore();
      const firebase = getFirebase();
      const path = 'users/'+firebase.auth().currentUser.uid+'/'+subject+'/'+topic+'/answers'

      firestore.collection(path).doc(id).set({
        answer: answerValue
      }).then(() => {
        dispatch({ type: 'SAVE_ANSWER_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'SAVE_ANSWER_ERROR' }, err);
      });
    }
  };