import React, { Component } from 'react';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import { Box, Container, withStyles, Button, Typography, ButtonBase } from '@material-ui/core'
import Subjects from './Subject.matrix';
import Landing from '../landing/Landing';
import Info from './Info';

const useStyles = theme => ({
    banner: {
        width: '100%',
        objectFit: 'cover',
        minHeight: 200,
        objectPosition: '15%'
    },
    title: {
        color: 'white',
        fontWeight: 800,
    },
    buttonoverlayed: {
        color: 'white',
        borderColor: 'white',
        margin: 8
    }
});

class Dashboard extends Component {
    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Landing />
        const { classes } = this.props;
        return (
            <div id="Dashboard" >
                <img
                    className={classes.banner}
                    alt="Banner"
                    src={require('./../static/banner_interno_2x.png')} />
                <Container >
                    <Box height="auto" width='100%' alignContent="center" marginBottom={1} display="flex" flexwrap="nowrap" justifyContent="flex-end">
                        <Box position='absolute' top='23%' >
                            <Typography className={classes.title} variant='h5'>¿Estás listo para probar<br />tus conocimientos?</Typography>
                        </Box>
                    </Box>
                </Container>
                <Container >
                    <Info />
                </Container>
                <Box bgcolor="secondary.light" color="white" marginTop={2}>
                    <Container>
                        <Typography variant='h6'><strong>“Somos lo que hacemos repetidamente. La excelencia entonces no es un acto, sino un hábito”</strong></Typography>
                        <Typography variant='subtitle1'>Aristóteles</Typography>
                    </Container>
                </Box>
                <Container >
                    <Subjects />
                </Container>

                <Box bgcolor="secondary.main" color="white" marginTop={2}>
                    <Container>
                        <ButtonBase disabled={false} component={NavLink} to='/examen' width='100%'>
                            <Box padding={2} className={classes.box} flexDirection='column'>
                                <Typography variant='h6' >¿Estás listo para probar tus conocimientos?</Typography>
                                <Button disabled={false} variant='outlined' color='inherit' size='large' fullWidth >Simulador</Button>
                            </Box>
                        </ButtonBase>
                    </Container>
                </Box>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

export default compose(
    withStyles(useStyles, { withTheme: true }),
    connect(mapStateToProps),
)(Dashboard);