import React from 'react';
import {
    Typography, Box, Card, CardContent, CircularProgress, Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        marginBottom: 20,
    },
    text: {
        overflow: 'auto',
        maxHeight: '60vh',
        align: "justify"
    },
    media: {
        height: 'auto',
        maxHeight: 300,
        width: '100%',
        objectFit: 'contain',
    },
}))

export const TextItem = ({
    text,
}) => {
    const classes = useStyles();
    if (text && text.text) {
        return (
            <Card >
                <CardContent >
                    {/* {
                        text && text.instrucciones ?
                            <Typography variant="subtitle2" color="textSecondary">
                                {text.instrucciones}
                            </Typography>
                            :
                            null
                    } */}
                    {
                        text && text.title ?
                            <Typography variant="h4" color="primary">
                                {text.title}
                            </Typography>
                            :
                            null
                    }
                    {
                        text && text.author ?
                            <Typography variant="subtitle1" color="textSecondary">
                                {text.author}
                            </Typography>
                            :
                            null
                    }

                    <Box className={classes.text} padding={1}>
                        <Typography
                            className={classes.root}
                            variant="body2" align="justify"
                            dangerouslySetInnerHTML={{__html:text.text}}
                        ></Typography>

                        {/* <Typography variant="body2" align="justify" >


                        </Typography>
                        {text.text.split('///').map((line, index) => {
                            return <ReactMarkdown source={line} key={index}></ReactMarkdown>
                        })} */}
                    </Box>
                    {
                        text && text.reference ?
                            <Typography variant="subtitle1" color="textSecondary">
                                {text.reference}
                            </Typography>
                            :
                            null
                    }

                </CardContent>
            </Card>);
    } else {
        return (
            <Paper width='100%'>
                <Box display='flex' flexDirection='column' justifyContent='center' height='100%' >
                    <Box m={2}>
                        <CircularProgress />
                        <Typography variant="h6" color="primary">
                            Cargando lectura...
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        )
    }
}