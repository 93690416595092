import React from "react";
import {
  Typography,
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Container,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Iframe from "react-iframe";

const useStyles = makeStyles((theme) => ({
  heading1: {
    fontWeight: 900,
    textAlign: "left",
    color: theme.palette.primary.main,
  },
  heading2: {
    fontWeight: 600,
    textAlign: "left",
    fontStyle: "italic",
    color: theme.palette.secondary.main,
  },
  item: {
    padding: theme.spacing(0, 2, 1, 0),
  },
  bullet: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.info.main,
  },
  ratioWrapper: {
    width: "100%",
    height: 0,
    paddingBottom: "56.25%",
    position: "relative",
  },
  videoWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  video: {
    width: "100%",
    height: "100%",
  },
}));

function Bullet() {
  const classes = useStyles();
  return (
    <ListItemAvatar>
      <Avatar className={classes.bullet}>
        <img
          width="12px"
          alt="icono admissio"
          src={require("./../static/curso_admissio.svg")}
        />
      </Avatar>
    </ListItemAvatar>
  );
}

export default function About() {
  const classes = useStyles();
  const puntos = [
    ["9 años de ", <strong>experiencia</strong>, " nos respaldan."],
    [
      "Una herramienta ",
      <strong>amigable</strong>,
      " para que estudies cualquier día y a cualquier hora.",
    ],
    [
      "Manuales con ",
      <strong>puntos clave y estrategias</strong>,
      " para cada tema.",
    ],
    [
      "Ejercicios diseñados con distintos ",
      <strong>niveles de dificultad.</strong>,
    ],
    ["Incluye ", <strong>todos los temas</strong>, " de tu examen."],
    ["Examenes ", <strong>simuladores</strong>, " para mejorar tus tiempos."],
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container>
      <Box
        display={isMobile ? "block" : "flex"}
        justifyContent="center"
        marginBottom={1}
        flexWrap="nowrap"
      >
        <Box flexShrink={1}>
          <Typography variant="h5" className={classes.heading1}>
            ESTE 2021 TÚ SÍ QUEDAS
          </Typography>
          <Typography variant="h6" className={classes.heading2}>
            EL MEJOR ALIADO PARA TU ADMISIÓN
          </Typography>
          <List disablePadding>
            {puntos.map((punto, index) => (
              <div key={index}>
                <ListItem padding={0} className={classes.item}>
                  <Bullet />
                  <ListItemText>{punto}</ListItemText>
                </ListItem>
              </div>
            ))}
          </List>
        </Box>
        <Box flexGrow={1}>
          <Box className={classes.ratioWrapper}>
            <Box className={classes.videoWrapper}>
              <Iframe
                url="https://www.youtube.com/embed/GvPEXkMQ_VE?controls=0&autoplay=1&rel=0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className={classes.video}
                frameBorder="0"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Container maxWidth="md">
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
          <img
            height="250px"
            alt="Banner"
            src={require("./../static/jenny.jpg")}
            style={{ objectFit: "fit" }}
            hidden={isMobile ? true : false}
          />
          <Box padding={2}>
            <Typography variant="body1" paragraph align="justify">
              Admissio es una <strong>metodología y plan de estudios</strong>{" "}
              diseñado por la <strong>Ing. Jennifer Morales,</strong>
              quien con <strong>más de 500 alumnos</strong> a lo largo de sus{" "}
              <strong>9 años</strong> de trayectoria, ha impartido el curso de
              manera exitosa, logrando por parte de sus estudiantes un{" "}
              <strong>90% de admisiones universitarias</strong> a las mejores
              instituciones de todo el país.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              align="justify"
              gutterBottom={false}
            >
              Esta metodología va dirigida a todo aquel alumno que hará un
              examen de admisión para{" "}
              <strong>cualquier carrera universitaria.</strong> Se ponen en
              práctica distintas estructuras de aciertos como: CENEVAL
              (Tecnológico de Tijuana e Ibero), College Board (CETYS,
              Xochicalco) y la última guía de UABC.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Container>
  );
}
