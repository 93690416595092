import React, { Component } from 'react';
import Carousel from 'react-material-ui-carousel'
import { QuoteItem } from './Quote.item';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Typography, Box, Container } from '@material-ui/core'


class QuoteList extends Component {
  render() {
    const { reviews } = this.props;

    return (
      <Box padding={1}>
        <Container m={1}>
          <Typography variant='button' color='secondary' >
          <Box fontSize="body1.fontSize" m={1} fontWeight='fontWeightBold'>¿Qué dicen de nosotros?</Box>
        </Typography>
        <Carousel
          autoPlay={true}
          interval={8000}>
          {reviews && reviews.map((value, index) => {
            return <QuoteItem index={index} quote={value.review} name={value.name} career={value.career} icon={value.icon} key={index}/>
          })}
        </Carousel>
        </Container>
        
      </Box>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    reviews: state.firestore.ordered.reviews,
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'reviews' }
  ])
)(QuoteList)