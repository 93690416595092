import React, { Component } from 'react';
import { Box, Container, withStyles, Typography, Button } from '@material-ui/core';
import { connect } from 'react-redux'
import { compose } from 'redux'
import QuoteList from './Quote.list';
import Plans from './Plans';
import About from './About';
import Dashboard from '../dashboard/Dashboard';
import imageBanner from './../static/Banner1.png'

const useStyles = theme => ({
    bannerBox: {
        backgroundImage: `url(${imageBanner})`,
        minHeight: '35vh',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        marginBottom: '16px'
    },
    
    bannerContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "flex-end"
    },
    title: {
        color: 'white',
        fontWeight: 800,
    },
    buttonoverlayed: {
        color: 'white',
        borderColor: 'white',
        margin: 8
    },
    subtitles: {
        fontSize: '20px'
    },

});

class Landing extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    render() {
        const { auth } = this.props;
        const { classes } = this.props;
        if (auth.uid) return <Dashboard />
        const planesRef = this.myRef
        function handleBackClick() {
            planesRef.current.scrollIntoView({ behavior: 'smooth' })
        };
        
        return (
            <div>
                <Box className={classes.bannerBox}>
                    <Container height='100%'>
                        <Box className={classes.bannerContent} paddingTop='20px'>
                            <div>
                                <Typography className={classes.title} variant='h5' >Admissio, el mejor  <br /> aliado para tu admisión</Typography>
                                <Button
                                    className={classes.buttonoverlayed}
                                    variant='outlined'
                                    onClick={handleBackClick}>Ver planes</Button>
                            </div>

                        </Box>
                    </Container>
                </Box>
                <About />

                <Box bgcolor='white'>
                    <QuoteList />
                </Box>

                <Container ref={planesRef}>
                    <Plans />
                    
                </Container>
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

export default compose(
    withStyles(useStyles, { withTheme: true }),
    connect(mapStateToProps),
)(Landing);