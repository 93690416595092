import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, withStyles } from '@material-ui/core';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { ExampleItem } from './example.item';
import { saveExample } from '../../store/actions/exampleActions'
import LoadingTemplate from '../general/Loading';

const styles = (theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.5em'
        },
        '*::-webkit-scrollbar-track': {
            backgroundColor: '#f5f5f5',
            borderRadius: '20px',
            border: '1px solid white'
            // '-webkit-box-shadow': 'inset 0 0 6px rgba(190,190,190,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '20px',
            border: '1px solid #f5f5f5'
        }
    },
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    descriptionSubject: {
        textAlign: 'left',
        margin: theme.spacing(0, 0, 1, 0)
    },
    headerSubject: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        textAlign: 'left',
        margin: theme.spacing('auto', 0, 1, 0)
    }
});

class Example extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answerValue: null,
            progress: props.match.params.example,
            status: null,
            helperText: 'Revisar',
            example: props.example,
        }
        this.myRef = React.createRef();
        this.handleExampleChange = this.handleExampleChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if ((nextProps.example !== prevState.example)) {
            return {
                example: nextProps.example,
                answerValue: nextProps.answerValue ? nextProps.answerValue['answer'] : null,
                helperText: 'Revisar',
                status: null
            }
        }
        return null;
    }

    handleExampleChange(props) {
        this.setState({
            answerValue: props.answerValue,
            status: null,
            helperText: 'Revisar'
        });
    }

    handleCurrentExample = () => {
        const { quizData, progress } = this.state;
        let currentExample = quizData[progress];
        let ans = currentExample.answerValue;
        this.setState({ currentExample:currentExample, answerValue: ans });
    }

    handleRadioChange = (value) => {
        this.setState({
            answerValue: value,
            status: null,
            helperText: 'Revisar'
        });
    }

    onSubmit = (event) => {
        let { answerValue } = this.state;
        let { example } = this.props;
        this.props.saveExample(example.id, answerValue, this.props.subject, this.props.topic);
        if (answerValue === example.ans) {
            this.setState({
                status: true,
                helperText: '¡Correcto!'
            });
        } else {
            this.setState({
                status: false,
                helperText: 'Intenta de nuevo'
            });

        }
    }

    render() {
        const { classes } = this.props;
        const { auth, exampleIndex, example, subject, topic, totalExamples, text } = this.props;
        const { answerValue, status, helperText } = this.state;
        if (!auth.uid) return <Redirect to='/' />
        if (example) {
            return (
                <div id="Example" className="Content">
                    <Container className={classes.root}>
                        <ExampleItem
                            example={example}
                            text={text}
                            totalexamples={totalExamples}
                            index={exampleIndex}
                            subject={subject}
                            topic={topic}
                            answerValue={answerValue}
                            onSubmit={this.onSubmit}
                            handleRadioChange={this.handleRadioChange}
                            status={status}
                            helperText={helperText}
                            
                        />
                    </Container>
                </div>
            )
        } else {
            return (
                <LoadingTemplate/>
            )
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveExample: (id, answerValue, subject, topic) => dispatch(saveExample(id, answerValue, subject, topic))
    }
}

const mapStateToProps = (state, ownProps) => {
    const exampleList = state.firestore.data.examples ? state.firestore.ordered.examples : null;
    const example = exampleList ? exampleList[ownProps.match.params.example - 1] : null;
    const useranswers = state.firestore.data.useranswers ? state.firestore.data.useranswers : null
    const text = state.firestore.data.topic ? state.firestore.data.topic : null;
    const answerValue = example && useranswers && useranswers[example.id] ? useranswers[example.id] : null;
    const totalExamples = exampleList ? exampleList.length : null;
    return {
        subject: ownProps.match.params.subject,
        topic: ownProps.match.params.topic,
        totalExamples: totalExamples,
        exampleIndex: ownProps.match.params.example,
        answerValue: answerValue,
        example: example,
        text: text,
        auth: state.firebase.auth
    }
}

export default compose(
    withStyles(styles),
    connect(null, mapDispatchToProps),
    connect(mapStateToProps),
    firestoreConnect(props => {
        var collection = [
            {
                collection: 'users',
                doc: props.auth.uid,
                subcollections: [
                    {
                        collection: props.match.params.subject,
                        doc: props.match.params.topic,
                        subcollections: [
                            { collection: 'answers' }
                        ]
                    }
                ],
                storeAs: 'useranswers'
            }]
        if (props.match.params.subject === 'matematicas') {
            collection.push({
                collection: 'subject',
                doc: props.match.params.subject,
                subcollections: [
                    {
                        collection: 'topics', doc: props.match.params.topic,
                        subcollections: [
                            {
                                collection: 'examples',
                                orderBy: ['dificulty'],
                            }
                        ]
                    }
                ],
                storeAs: 'examples'
            })
        } else {
            collection.push({
                collection: 'subject',
                doc: props.match.params.subject,
                subcollections: [
                    { collection: 'topics', doc: props.match.params.topic }
                ],
                storeAs: 'topic'
            })
            collection.push({
                collection: 'subject',
                doc: props.match.params.subject,
                subcollections: [
                    {
                        collection: 'topics', doc: props.match.params.topic,
                        subcollections: [
                            {
                                collection: 'examples'
                            }
                        ]
                    }
                ],
                storeAs: 'examples'
            })
        }
        return collection
    },
    )

)(Example)