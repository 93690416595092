import React from 'react';
import { NavLink } from 'react-router-dom'
import { Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowSize } from 'react-use'
import Confetti from 'react-confetti'
import { Backdrop } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Score(score) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { width, height } = useWindowSize()


  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        {score.score > 1000 ?
          <Confetti
            width={width}
            height={height}
          /> : null}

        <Dialog
          // fullScreen={fullScreen}
          open={true}
          fullWidth={true}
          maxWidth='sm'
          onClose={handleClose}
          BackdropProps={{ style: { backgroundColor: 'transparent' } }}
          aria-labelledby="dialog-title"
        >

          <DialogTitle id="dialog-title">{"Resultado"}</DialogTitle>
          <DialogContent>
            <Box display='flex' justifyContent='center' m={1}>
              <img height="100px"
                alt="icono admissio"
                src={require('./../static/admissio_a.png')} />
            </Box>
            <DialogContentText align='center' variant='h5'>TU PUNTAJE ES</DialogContentText>
            <DialogContentText align='center' variant='h2'>{score.score}</DialogContentText>
            <DialogContentText align='center' variant='subtitle1'>/1300</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus component={NavLink} to='/' color="primary">
              Volver a inicio
          </Button>
          </DialogActions>
        </Dialog>
      </Backdrop>

    </div>
  );
}

export default Score;