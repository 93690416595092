import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoadingTemplate from '../general/Loading';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        marginBottom: 20,
    },
    text: {
        overflow: 'auto',
        maxHeight: '65vh'
    },
    media: {
        height: 'auto',
        maxHeight: 300,
        width: '100%',
        objectFit: 'contain',
    },
}))

export const ImageItem = ({
    imgName,
}) => {
    const classes = useStyles();
    console.log(imgName);
    var sourceImg = null;
    try {
        sourceImg = require('./../static/graphics/' + imgName + '.jpg');
    }
    catch (err) {
        try {
            sourceImg = require('./../static/graphics/' + imgName + '.png');
        }
        catch (err) {
            sourceImg = null;
        }
    }
    if (imgName && sourceImg) {
        return (
            <img
                className={classes.media}
                src={sourceImg}
                alt='Error de imagen'
            />);
    } else {
        return (
            <LoadingTemplate text='Cargando grafico...' />
        )
    }
}